<template>
  <div class="pc-top-nav">
    <div class="top-nav" :style="{ background: background, color: color }">
      <div class="logo-box">
        <img class="logo" v-if="$route.path.includes('product')" src="@/assets/pc_product_logo.png" alt="" />
        <img class="logo" v-else-if="showBlackLogo" src="@/assets/pc_black_logo.png" alt="" />
        <img class="logo" v-else src="@/assets/pc_logo.png" alt="" />
      </div>
      <div class="menu-list">
        <div
          :class="['menu-item', (toPath ? toPath.includes(item.activePath) : $route.path.includes(item.activePath)) ? 'menu-active' : '']"
          v-for="(item, index) in menuList"
          :key="index"
          @click="clickMenuItem(item)"
          @mouseenter="menuHover(item)"scrollHeight
          @mouseleave="showChild = false"
        >
          {{ item.name }}
          <div v-if="$route.path === item.path" class="line"></div>
        </div>
      </div>
      <div class="phone">
        <img class="phone-icon" src="@/assets/icons/pcIcons/icon_top_phone.png" alt="" />
        400-000-0000
      </div>
    </div>
    <transition name="fade">
      <div v-show="showChild" :class="['child-menu', $route.path === '/' ? 'black-bg' : '']">
        <div class="child-item" v-for="(item, index) in productMenu" :key="index">
          {{ item.name }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'PcTopNav',
    data() {
      return {
        activeMenu: '',
        menuList: [],
        productMenu: [],
        showChild: false
      }
    },
    props: {
      color: {
        type: String,
        default: '#FFF'
      },
      background: {
        type: String,
        default: 'rgba(0, 0, 0, 0.1)'
      },
      showBlackLogo: {
        type: Boolean,
        default: true
      },
      toPath: {
        type: String,
        default: ''
      }
    },

    mounted() {
      this.menuList = this.$commonData.menuList
      this.productMenu = this.$commonData.productMenuList
    },

    methods: {
      clickMenuItem(item) {
        this.$router.push({
          path: item.path
        })
      },
      menuHover(item) {
        if (item.name === '产品中心' || item.name === '参数分类' || item.name === '应用领域') {
          this.showChild = true
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .pc-top-nav {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    box-sizing: border-box;
    .top-nav {
      display: flex;
      align-items: center;
      width: 100%;
      height: 110px;
      padding: 0 160px;
      .logo-box {
        .logo {
          width: 238px;
          height: 54px;
        }
      }
      .menu-list {
        display: flex;
        align-items: center;
        height: 100%;
        margin: 0 117px 0 92px;
        .menu-item {
          position: relative;
          display: flex;
          align-items: center;
          height: 100%;
          margin: 0 20px;
          font-size: 18px;
          cursor: pointer;
          transition: color .3s linear;
          &:hover {
            color: #00DDBF;
          }
          .line {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            border-radius: 2px;
            background-color: #00DDBF;
            animation: scaleAnimation .3s linear;
          }
        }
        .menu-active {
          color: #00DDBF;
          transition: color .3s linear;
        }
      }
      .phone {
        display: flex;
        align-items: center;
        .phone-icon {
          width: 25px;
          height: 24px;
          margin-right: 10px;
        }
        font-size: 24px;
        font-weight: 700;
        color: #FFF
      }
    }
    .child-menu {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, .8);
      .child-item {
        font-size: 16px;
        padding: 0 20px;
      }
    }
    .black-bg {
      color: #FFF;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  /* 定义过渡效果 */
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
  @keyframes scaleAnimation {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
