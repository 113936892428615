<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
    mounted() {
      // this.setScale()
      // window.addEventListener('resize', this.setScale)
    },
    methods: {
      // getScale() {
      //   const width = window.screen.width
      //   const height = window.screen.height
      //   let ww = window.innerWidth / 1920
      //   let wh = window.innerHeight / 1080
      //   return ww < wh ? ww : wh
      // },
      // setScale() {
      //   this.scale = this.getScale()
      //   this.$refs.newCatalog.style.setProperty('--scale', this.scale)
      // }
    }
  }
</script>
<style>
  #app {
    color: #333;
    box-sizing: border-box;
    font-family: HarmonyOS Sans SC;
  }
</style>
