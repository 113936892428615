import { createRouter, createWebHistory } from "vue-router";
import { mobileRouter } from "./mobileRouter";
import { pcRouter } from "./pcRouter";
import { watch } from "vue";
// let routes;
let routes = [
  {
    path: '/pcPages', // 默认的pc第一个页面
    name: 'PcIndex',
    component: () => import('../views/pcPages/index.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/pcPages1', // pc第二个页面跳转小程序的
    name: 'PcIndex1',
    component: () => import('../views/pcPages/index1.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/pcPages2', // pc第三个页面底部有地址的
    name: 'PcIndex2',
    component: () => import('../views/pcPages/index2.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/demo', // 测试功能的
    name: 'demo',
    component: () => import('../views/pcPages/demo.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/mobilePages',  
    name: 'mobilePages',
    component: () => import('../views/mobilePages/mobilePages.vue'),
    meta: { type: 'pc' }
  },
  // {
  //   path: '/mobilePages',  //移动端文章页面 百度
  //   name: 'mobilePages1',
  //   component: () => import('../views/mobilePages/index.vue'),
  //   meta: { type: 'pc' }
  // },
  {
    path: '/mobilePages2',   //移动端文章二个页面 普通vivo
    name: 'mobilePages2',
    component: () => import('../views/mobilePages/mobilePages2.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/mobilePages3',  //移动端第三个页面
    name: 'mobilePages3',
    component: () => import('../views/mobilePages/mobilePages3.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/page',    //移动端第三个页面跳转页面 vivo
    name: 'page',
    component: () => import('../views/mobilePages/page.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/mobilePages4',   //移动端第四个页面 百度
    name: 'mobilePages4',
    component: () => import('../views/mobilePages/mobilePages4.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/page1',   //移动端第四个页面跳转页面
    name: 'page1',
    component: () => import('../views/mobilePages/page1.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/mobilePages5',   //移动端表单 跳转小程序
    name: 'mobilePages5',
    component: () => import('../views/mobilePages/mobilePages5.vue'),
    meta: { type: 'pc' }
  },
  // {
  //   path: '/mobilePages6',   //移动端软文 跳转小程序
  //   name: 'mobilePages6',
  //   component: () => import('../views/mobilePages/mobilePages6.vue'),
  //   meta: { type: 'pc' }
  // },
  {
    path: '/mobilePages7',   //移动端软文 跳转小程序
    name: 'mobilePages7',
    component: () => import('../views/mobilePages/mobilePages7.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/mobilePages8',   //定位小程序
    name: 'mobilePages8',
    component: () => import('../views/mobilePages/mobilePages8.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/mobilePages9',   //快速跳转小程序
    name: 'mobilePages9',
    component: () => import('../views/mobilePages/mobilePages9.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/mobilePages10',   //按摩推拿表单跳转
    name: 'mobilePages10',
    component: () => import('../views/mobilePages/mobilePages10.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/mobilePages11',   //膏滋快速跳转
    name: 'mobilePages11',
    component: () => import('../views/mobilePages/mobilePages11.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/mobilePages12',   //推拿2.8跳转
    name: 'mobilePages12',
    component: () => import('../views/mobilePages/mobilePages12.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/wxLink',   //
    name: 'wxLink',
    component: () => import('../views/mobilePages/relay/relay.vue'),
    meta: { type: 'pc' }
  },
  {
    path: '/echarts',   //
    name: 'echarts',
    component: () => import('../views/mobilePages/echarts.vue'),
    meta: { type: 'pc' }
  },
]
// let screenWidth = document.body.clientWidth;

// console.log(routes, 123);
// window.onresize = () => {
//   screenWidth = document.body.clientWidth;
// };

// watch(
//   screenWidth,
//   (newVal, oldVal) => {
//     let timer = setTimeout(() => {
//       if (newVal <= 758) {
//         routes = [...routes, ...mobileRouter];
//         window.location.reload();
//       } else {
//         routes = [...routes, ...pcRouter];
//         window.location.reload();
//       }
//     }, 500);
//     return () => {
//       clearTimeout(timer);
//     };
//   },
//   { deep: true }
// );

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
